import axios from 'axios';
import store from '@/store'
const monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"]
const baseUrl = 'https://pam.backend.hmgbs.com'
//const baseUrl = 'http://localhost:3000'

async function sendAPIRequest(endpoint, method, dataOrParams, storeKey = null) {
    let accessToken = localStorage.getItem("accessToken-storage");
    store.commit("setIndicateLoading", true)

    await axios({
        method: method,
        url: baseUrl + endpoint,
        data: method === "POST" || method === "PUT" || method === "PATCH" || method === "DELETE" ? dataOrParams: {},
        params: method === "GET" ? dataOrParams: {},
        headers: {
            Authorization: 'Bearer ' + accessToken
        }
    }).then(function(response) {
        if (storeKey != null) {
            store.commit(storeKey, response.data)
        }
        store.commit("setIndicateLoading", false)
    }).catch(function(error) {
        console.log(error)
        store.commit("setIndicateError", true)
        throw error; // rethrow error to handle up the stack
    })
}

/// new routes
async function getStates() {
    await sendAPIRequest("/state", "GET", {}, "setStates")
}

async function patchState(year, month, sspName, stageLabel, isResolved) {
    await sendAPIRequest("/state", "PATCH", {
        "acst_year": year,
        "acst_month": month,
        "ssp_cntr": store.getters.getSellingSidePlatform(sspName).ssp_cntr,
        "acsg_cntr": store.getters.getStageByLabel(stageLabel).acsg_cntr,
        "acst_isresolved": isResolved
    })
}

async function resolveState(year, month, sspName, stageLabel) {
    await patchState(year, month, sspName, stageLabel, true)
}

async function getStages() {
    await sendAPIRequest("/stage", "GET", {}, "setStages")
}

async function getSellingSidePlatform() {
    await sendAPIRequest("/sellingSidePlatform", "GET", {}, "setSellingSidePlatforms")
}

// patch SSP to update user note
async function patchSellingSidePlatform(sspName, userNote) {
    await sendAPIRequest("/sellingsideplatform", "PATCH", {
        "ssp_name": sspName,
        "ssp_userNote": userNote
    })
}

async function getRevenue() {
    await sendAPIRequest("/revenue", "GET", {}, "setRevenue")
}

async function deleteRevenue(selectedYear, selectedMonth, connectionType, source) {
    await sendAPIRequest("/revenue", "DELETE", {
        "rev_year": selectedYear,
        "rev_month": selectedMonth,
        "rev_connectionType": connectionType,
        "rev_source": source,
    })
}

async function putRevenue(selectedYear, selectedMonth, connectionType, source, n2, isInvoiceFromPBI, isUSD) {
    await sendAPIRequest("/revenue","PUT", {
        "rev_year": selectedYear,
        "rev_month": selectedMonth,
        "rev_connectionType": connectionType,
        "rev_source": source,
        "rev_n2": n2,
        "rev_isUSD": isUSD,
        "rev_invoiceFromPBI": isInvoiceFromPBI
    })
}

async function getEvents() {
    await sendAPIRequest("/event","GET", {}, "setEvents")
}

async function postEvent(selectedYear, selectedMonth, selectedSSP, eventType, commentText = "", value = "") {
    await sendAPIRequest("/event", "POST",  {
        "tle_year": selectedYear,
        "tle_month": selectedMonth,
        "tle_ssp": selectedSSP,
        "tle_commentText": commentText,
        "tle_value": value,
        "tle_eventtype": eventType,
        "tle_userimage": localStorage.getItem("userImage")
    })
}

async function postComment(selectedYear, selectedMonth, selectedSSP, commentText) {
    await postEvent(selectedYear, selectedMonth, selectedSSP, "COMMENT", commentText)
}

async function getCurrencyConversion(date, value) {
    await sendAPIRequest(
        "/conversionRate?base_currency=USD&currencies=EUR&date=" + date + "&value=" + value,
        "GET",
        {},
        "setCurrencyConversion"
    )
}

async function postADBJobRun() {
    await sendAPIRequest(
        "/adbjobrun",
        "POST",
        {}
    )
}

export default {
    baseUrl,
    monthNames,
    getStates,
    getStages,
    patchState,
    resolveState,
    getSellingSidePlatform,
    patchSellingSidePlatform,
    getRevenue,
    deleteRevenue,
    putRevenue,
    getEvents,
    postEvent,
    postComment,
    postADBJobRun,
    getCurrencyConversion
}
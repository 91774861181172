<template>
  <transition>
    <div @click.self="this.$emit('closeOverlay')" class="bg-[#37415165] v-screen h-screen fixed left-0 right-0 top-0 z-40">
      <div class="bg-[#374151F5] fixed left-[calc(50vw-325px)] top-36 z-50 w-[700px] h-[575px] overflow-hidden rounded-md shadow-2xl text-white  border border-gray-600">
        <div class="flex mx-5 text-xl mt-1">
          <span class="p-2 flex-grow">Belegwert eintragen</span>
          <span class="px-2 py-1 my-1 hover:bg-gray-500 rounded-sm cursor-pointer" @click="this.$emit('closeOverlay')"><font-awesome-icon icon="fa-xmark" /></span>
        </div>
        <hr class="border-t border-gray-200 mt-1 mb-4" />
        <div class="mx-14 w-fit relative text-left">
          <span class="pb-1">Bitte gib den Rechnungsbelegwert für folgende Abrechnung ein:</span>
          <hr class="border-t border-gray-200 my-2"/>
          <div>
            <ul>
              <li><strong>Abrechnungsmonat:</strong> {{ api().monthNames[selectedMonth - 1] }} {{ selectedYear }}</li>
              <li><strong>SSP:</strong> {{ selectedSSP.split("-")[0] }}</li>
            </ul>
          </div>
          <hr class="border-t border-gray-200 my-4" />
          <div>
            <input v-model="revenueInput" id="revenue" class="invalid:text-red-500 text-black py-1 px-2 w-60 h-[30px]" lang="de-DE" step="0.01" placeholder="0,00" max="999999" required>
            <select class="ml-2 text-black pt-1.5 pb-1 px-2 text-sm w-72" v-model="selectedConnectionType">
              <option>-bitte auswählen-</option>
              <option v-for="connection in $store.getters.getConnectionNamesForSellingSidePlatform(this.selectedSSP)">
                {{ connection }}
              </option>
            </select>
          </div>
          <div class="text-xs mt-2"><pre>Zugeordnete ConnectionTypes: {{ JSON.stringify(addedConnectionTypes, null, 2) }}</pre></div>
          <div>
            <button @click="addConnectionType()" class="w-[266px] text-white text-xs bg-gray-800 border-1 border-white pt-2.5 pb-2 mt-2">
              <font-awesome-icon icon="fa-plus" class="text-md mr-1" /> ConnectionType hinzufügen
            </button>
            <button @click="putInvoiceRevenueFromPowerBI" class="w-[266px] text-white text-xs bg-gray-800 border-1 border-white pt-2.5 pb-2 ml-1">
              <font-awesome-icon icon='fa-file-import' class="text-md mr-1" /> PowerBI übernehmen
            </button>
            <button @click="putInvoiceRevenueFromUSD()" class="w-[266px] text-white text-xs bg-gray-800 border-1 border-white mt-1 pt-2.5 pb-2">
              <font-awesome-icon icon="fa-file-invoice-dollar" class="text-md mr-1" /> USD-Wert konvertieren
            </button>
            <button @click="putInvoiceRevenue()" class="w-[266px] text-white text-xs bg-gray-800 border-1 border-white pt-2.5 pb-2 mt-1 ml-1">
              <font-awesome-icon icon="fa-floppy-disk" class="text-md mr-1" /> Speichern
            </button>
          </div>
        </div>
        <hr class="border-t border-gray-200 my-4 mx-14" />
        <p class="text-left border border-gray-800 px-2 py-1 mx-16" v-if="invoicesExist"><strong class="text-left">Aktuell gesetzte Belegwerte:</strong></p>
        <div class="flex text-left ml-16 w-[570px] border-b border-gray-800" v-if="invoicesExist" v-for="connection in $store.getters.getRevenueForAllConnections(this.selectedYear, this.selectedMonth, this.selectedSSP, 'INVOICE')">
            <div class="w-48 border-l border-gray-800 px-2 py-1 text-xs" v-html="'• ' + connection.rev_connectionType.toString().replace(',', '</br/> • ')"></div>
            <div class="w-52 border-l border-gray-800 px-2 py-1">{{ formatRevenue(connection.rev_n2) }} EUR</div>
            <div class="w-36 border-l border-gray-800 px-2 py-1">{{ connection.rev_invoiceFromPBI ? "PowerBI-Wert" : "Belegwert" }}</div>
            <button @click="deleteInvoiceRevenue(selectedYear, selectedMonth, connection.rev_connectionType, 'INVOICE')" class="text-white text-xs bg-gray-800 border-1 border-white py-2 px-3 hover:text-red-400">
              <font-awesome-icon icon="fa-trash-can" class="text-md" />
            </button>
        </div>
        <div class="ml-18" v-else>
          -- Keine Belegwerte gesetzt --
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import api from "@/js/api";
export default {
  name: "invoiceImportOverlay",
  props: ["selectedYear", "selectedMonth", "selectedSSP"],
  data() {
    return {
      revenueInput: "",
      isUSD: false,
      selectedConnectionType: "-bitte auswählen-", // current selection
      addedConnectionTypes: [] // confirmed selected
    }
  },
  computed: {
    invoicesExist: function() {
      return this.$store.getters.getRevenueForAllConnections(this.selectedYear, this.selectedMonth, this.selectedSSP, 'INVOICE').length > 0
    }
  },
  async mounted() {
    await api.getRevenue()
  },
  methods: {
    api() {return api},
    addConnectionType() {
      if (!this.addedConnectionTypes.includes(this.selectedConnectionType) && this.selectedConnectionType !== "-bitte auswählen-") {
        this.addedConnectionTypes.push(this.selectedConnectionType)
      }
    },
    async putInvoiceRevenue(isInvoiceFromPBI = false) {
      let parsedNumber = parseFloat(this.revenueInput.replace(".", "").replace(",", "."))
      if (this.addedConnectionTypes.length === 0) {
        this.$store.commit("showErrorAlert", "Bitte ordne mindestens einen ConnectionType hinzu!")
        return
      } else if (!Number.isFinite(parsedNumber) || parsedNumber === 0 ||parsedNumber > 999999){
        this.$store.commit("showErrorAlert", "Fehlerhafte Eingabe!")
        return
      }

      try {
        await api.putRevenue(this.selectedYear, this.selectedMonth, this.addedConnectionTypes, "INVOICE", parsedNumber, isInvoiceFromPBI)
      } catch {
        this.$store.commit("showErrorAlert", "Belegwert konnte nicht gespeichert werden!")
        return
      }
      await api.getRevenue()
      this.$store.commit("showSuccessAlert", "Belegwert erfolgreich eingetragen!")

      // post event to timeline
      await api.postEvent(
          this.selectedYear,
          this.selectedMonth,
          this.selectedSSP,
          isInvoiceFromPBI ? "PBIINVOICECOPY" : "INVOICESET",
          null,
          parsedNumber.toString() + " € " + " für " + this.addedConnectionTypes.toString().replace("[", "").replace("]", "")
      )

      // cleanup
      this.revenueInput = "";
      this.selectedConnectionType = "-bitte auswählen-"
      this.addedConnectionTypes = []
    },
    async putInvoiceRevenueFromUSD() {
      if (this.revenueInput === "") {
        this.$store.commit("showErrorAlert", "Fehlerhafte Eingabe!")
        return
      }

      if (confirm("Bist du sicher, dass du den aktuellen Wert konvertiert von USD zu EUR als Belegwert eintragen möchtest?")) {
        let dateToConvert = new Date(this.selectedYear, this.selectedMonth, 1).toISOString().substring(0, 10);
        await api.getCurrencyConversion(dateToConvert, this.revenueInput)
        let result = this.$store.state.apiData.currencyConversion.value
        if (result === null || result === 0 || result === "") {
          this.$store.commit("showErrorAlert", "Fehler bei Wechselkursanfrage!")
          return
        }
        this.revenueInput = this.$store.state.apiData.currencyConversion.value ?? 0
        await this.putInvoiceRevenue(false);
      }
    },
    async putInvoiceRevenueFromPowerBI() {
      if (confirm("Bist du sicher, dass den Belegwert aus PowerBI übernehmen möchtest?")) {
        this.revenueInput = this.$store.getters.getRevenueForConnection(this.selectedYear, this.selectedMonth, this.selectedSSP, this.selectedConnectionType, "POWERBICORRECTED")
        if (this.revenueInput == null) {
          this.$store.commit("showErrorAlert", "Fehler: Kein PowerBI-Wert zum Übernehmen vorhanden!")
        }
        await this.putInvoiceRevenue(true);
      }
    },
    async deleteInvoiceRevenue(selectedYear, selectedMonth, connectionType) {
      if (confirm("Bist du sicher, dass du diesen Belegwert löschen möchstest?")) {
        await api.deleteRevenue(selectedYear, selectedMonth, connectionType, 'INVOICE')
        await api.getRevenue()
        this.$store.commit("showSuccessAlert", "Belegwert gelöscht!")
      }
    },
    formatRevenue(revenue) {
      return parseFloat(revenue).toLocaleString('de')
    },
  }
}
</script>

<style scoped>
.v-enter-active, .v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter-from, .v-leave-to {
  opacity: 0;
}
</style>
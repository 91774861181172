<template>
  <div>
    <!-- Timeline Header -->
    <div class="flex">
      <span class="font-bold">Historie & Kommentare</span>
      <div class="flex-grow"></div>
      <span v-if=isWebSocketLive class="text-green-500 text-sm float-right animate-pulse">
        Verbunden <font-awesome-icon :icon="['fas', 'link']" />
      </span>
      <span v-else=isWebSocketLive class="text-red-500 text-sm">
        Nicht verbunden <font-awesome-icon :icon="['fas', 'link']" />
      </span>
    </div>

    <!-- Timeline Messages -->
    <div class="rounded-sm border border-b-0 border-gray-400 mt-2 h-[calc(100vh-500px)] overflow-y-scroll">
      <div class="mt-3">
        <ol v-for="event in $store.getters.getEvents(this.selectedYear, this.selectedMonth, this.selectedSSP)" class="relative border-l-2 border-gray-400 last:border-gray-200 ml-7 mr-5">
          <li class="pb-2 ml-7">
            <div class="absolute flex items-center justify-center w-8 h-8 -left-4">
                <img v-if="event.tle_username === 'PAM'" alt="User Image PAM" src="/img/icons/icon-96x96.png" class="rounded-full border border-gray-400"/>
                <img v-else-if="event.tle_userimage != null" alt="User Image" class="rounded-full" :src="event.tle_userimage"/>
                <div v-else class="rounded-full bg-red-400 w-full h-full text-white pt-1.5 text-sm">
                  {{ event.tle_username.split(' ')[0].charAt(0) }}{{ event.tle_username.split(' ')[1].charAt(0) }}
                </div>
            </div>
            <div class="px-4 py-2 border rounded-sm shadow-sm bg-gray-200 border-gray-400">
              <div class="items-center justify-between sm:flex">
                <div class="text-sm font-normal text-black text-left break-words w-10/12">
                  <strong class="mr-1">{{ event.tle_username}}</strong>
                  <!-- v-if for different event types here -->
                  <span v-if="event.tle_eventtype==='COMMENT'">hat kommentiert:
                    <strong v-if="event.tle_commentText" class="text-black">"{{ event.tle_commentText }}"</strong>
                  </span>
                  <span v-else-if="event.tle_eventtype==='INIT'">hat die Abrechnung initial angelegt.</span>
                  <span v-else-if="event.tle_eventtype==='POWERBICOMPLETE'">hat Umsätze für alle Tage in PowerBI erkannt.</span>
                  <span v-else-if="event.tle_eventtype==='INVOICESET'">hat einen Rechungsbelegwert von <strong>{{
                      parseFloat(event.tle_value.split("€")[0]).toFixed(2).toString().replace(".", ",") + "€" + event.tle_value.split("€")[1]
                  }}</strong> erfasst.</span>
                  <span v-else-if="event.tle_eventtype==='CORRECTION'">hat eine Umsatzkorrektur auf <strong>{{
                    parseFloat(event.tle_value).toFixed(2).toString().replace(".", ",")
                  }}€</strong> erkannt.</span>
                  <span v-else-if="event.tle_eventtype==='NT-IMPORT'">hat einen Nautilus-Import erkannt:
                    <span v-html="cleanTLEValue(event.tle_value)"></span></span> <!-- We need line breaks so we parse raw HTML-->
                  <span v-else-if="event.tle_eventtype==='REVENUESANE'">hat erkannt, dass die Umsätze keine Differenzen mehr aufweisen.</span>
                  <span v-else-if="event.tle_eventtype==='FAKTURA'">hat erkannt, dass die Umsätze in Nautilus fakturiert wurden.</span>
                  <span v-else-if="event.tle_eventtype==='FILEADD'">hat eine Datei <strong>{{event.tle_value}}</strong> hochgeladen.</span>
                  <span v-else-if="event.tle_eventtype==='FILEDELETE'">hat die Datei <strong>{{event.tle_value}}</strong> gelöscht.</span>
                  <span v-else-if="event.tle_eventtype==='OVERRIDEPOWERBI'">hat die PowerBI-Umsätze als vollständig markiert.</span>
                  <span v-else-if="event.tle_eventtype==='OVERRIDEDIFF'">hat die Umsatzdifferenzen als akzeptabel markiert.</span>
                  <span v-else-if="event.tle_eventtype==='FAKTURA_UNVAL'" class="text-red-600">hat erkannt, dass die Umsätze in Nautilus nicht mehr fakturiert sind!</span>
                  <span v-else class="text-red-600 animate-ping">hat was kaputt gemacht!</span> <!-- We should know all event types -->
                </div>
                <time class="mt-0.5 mb-1 self-start text-xs font-normal text-gray-400 sm:order-last sm:mb-0">{{ event.tle_timestamp.substring(0, 19).replace("T", " ") }}</time>
              </div>

            </div>
          </li>
        </ol>
      </div>
    </div>
    <div class="border border-t-0 border-gray-400 h-12 flex">
      <input v-model="commentText" @keydown.enter="saveComment" class="w-full h-full bg-gray-300 px-3 !outline-none" type="text" placeholder="Schreibe einen Kommentar..."/>
      <button @click="saveComment" class="text-white text-xs bg-gray-700 border-1 border-white py-1 px-5 ">Senden</button>
    </div>
  </div>
  <!-- Useless Easter Egg -->
  <div v-if="commentText.toLowerCase().includes('weeee')" class="fixed text-red-600 text-[250px] top-56 left-0 right-0 z-50 animate-spin">WEEEEE!</div>
</template>

<script>
import api from "@/js/api";
export default {
  name: "TimelineView",
  methods: {
    api() {return api},
    async saveComment() {
      if (this.commentText === "") return
      await api.postComment(this.selectedYear, this.selectedMonth, this.selectedSSP, this.commentText);
      await api.getEvents(this.selectedYear, this.selectedMonth, this.selectedSSP);
      this.commentText = ""
    },
    initWebsocket() {
      // Init websocket to get real time updates
      //let connection = new WebSocket('ws://localhost:3000');
      let connection = new WebSocket('wss://pam.backend.hmgbs.com');
      connection.onmessage = async (event) => {
        if (event.data === "NEW_EVENT_AVAILABLE") {
          console.log("Websocket: New events available!")
          await api.getEvents(this.selectedYear, this.selectedMonth, this.selectedSSP);
        } else if (event.data === "HEARTBEAT") {
          this.isWebSocketLive = true
          this.webSocketStateResetRequested = false // Dont reset if we just got a heartbeat
          console.info("Websocket heartbeat recieved")
        } else {
          console.warn("Unknown websocket event received!")
        }
      }
    },
    cleanTLEValue(value) {
      console.log(value)
      return value.replace( // cleanup empty lines - todo: clean in database
        new RegExp('<u>(Twiago Recommendation Ad|Open Bidding \\(Google\\)|AdServer LineItem|TAM \\(Amazon\\)|Server2Server Bidding \\(Xandr\\)|Header Bidding \\(Prebid\\))<\\\/u><br\\\/> ● (<br\\\/>|$)', 'g'),
      "").replace("<s> ", " <s>")
    }
  },
  props: ["selectedYear", "selectedMonth", "selectedSSP"],
  async mounted() {
    await api.getEvents(this.selectedYear, this.selectedMonth, this.selectedSSP);

    this.initWebsocket()

    // reset websocket state so it goes false if we dont get a heartbeat
    setInterval(() => {
      if (this.isWebSocketLive) {
        this.webSocketStateResetRequested = true;
        // wait for specified time if we get an new update
        // this avoids flickering of the state indicator
        setTimeout(() => {
          if (this.webSocketStateResetRequested) {
            this.isWebSocketLive = false
          }
        }, 1500)
      } else {
        // try to reestablish websocket
        console.warn("Websocket not connected. Trying to reestablish...")
        this.initWebsocket()
      }
    }, 2500)
  },
  data() {
    return {
      commentText: "",
      isWebSocketLive: false,
      webSocketStateResetRequested: false
    }
  }
}
</script>

<style scoped>
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
</style>